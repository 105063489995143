define("product-management/pods/components/pm/ui/nav/hamburger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+lK/+KP",
    "block": "{\"symbols\":[\"@bgColor\",\"@textColor\",\"&default\"],\"statements\":[[11,\"nav\"],[16,1,[34,0]],[24,0,\"text-right items-center bg-transparent\"],[4,[38,1],[[32,0,[\"handleInsert\"]]],null],[4,[38,2],[[32,0,[\"handleDestroy\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"block mb-2\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"\\n        items-center \\n        px-4 \\n        py-4 \\n        border \\n        rounded \\n        \",[30,[36,3],[[32,2],[32,2],\"text-white hover:text-white hover:border-white \"],null],\" \\n        border-teal-light \\n        \",[30,[36,3],[[32,1],[32,1],\"bg-indigo-light\"],null]]]],[4,[38,4],[\"click\",[32,0,[\"toggleOpen\"]]],null],[12],[2,\"\\n      \"],[10,\"svg\"],[14,0,\"fill-current h-3 w-3\"],[14,\"viewBox\",\"0 0 20 20\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[10,\"title\"],[12],[2,\"Menu\"],[13],[10,\"path\"],[14,\"d\",\"M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n      \"],[18,3,[[30,[36,8],null,[[\"content\",\"close\"],[[30,[36,7],[\"pm/ui/nav/hamburger/content\"],[[\"menuClass\"],[[35,6]]]],[30,[36,5],[[32,0,[\"toggleOpen\"]]],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"menuId\",\"did-insert\",\"will-destroy\",\"if\",\"on\",\"fn\",\"menuClass\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/nav/hamburger/template.hbs"
    }
  });

  _exports.default = _default;
});